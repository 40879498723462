var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
        _vm._v("Estabelecimentos"),
      ]),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "backgroundSecond" } },
                [_c("v-spacer")],
                1
              ),
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              id: "grid-estabelecimentos",
              headers: _vm.headers,
              items: _vm.estabelecimentos,
              "server-items-length": _vm.totalEstablishment,
              loading: _vm.loading,
              options: _vm.options,
              "footer-props": {
                "items-per-page-options": [10, 25, 50],
              },
            },
            on: {
              "update:options": function ($event) {
                _vm.options = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.details.matriz",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("cnpj")(item.details.matriz)) + " "
                    ),
                  ]
                },
              },
              {
                key: "item.details.cnpj",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("cnpj")(item.details.cnpj)) + " "
                    ),
                  ]
                },
              },
              {
                key: "item.details.cnae",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("cnae")(item.details.cnae)) + " "
                    ),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "AGTooltip",
                      {
                        attrs: { "tooltip-text": "Obter informações da DCTF" },
                      },
                      [
                        _vm.perfis.includes("ROLE_ADMIN")
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mr-2 button-size",
                                attrs: {
                                  id: "button-message-to-queue-`${item.details.cnpj}`",
                                  icon: "",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.sendMessageToQueue(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mr-2", attrs: { small: "" } },
                                  [_vm._v(" mdi-robot-outline ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }