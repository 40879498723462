<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">Estabelecimentos</h2>
    <v-card>
      <v-card-title>
        <v-toolbar flat color="backgroundSecond">
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-card-title>
      <v-data-table
        id="grid-estabelecimentos"
        :headers="headers"
        :items="estabelecimentos"
        class="elevation-1"
        :server-items-length="totalEstablishment"
        :loading="loading"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
      >
        <template v-slot:item.details.matriz="{ item }">
          {{ item.details.matriz | cnpj }}
        </template>
        <template v-slot:item.details.cnpj="{ item }">
          {{ item.details.cnpj | cnpj }}
        </template>
        <template v-slot:item.details.cnae="{ item }">
          {{ item.details.cnae | cnae }}
        </template>
        <template v-slot:item.actions="{ item }">
          <AGTooltip tooltip-text="Obter informações da DCTF">
            <v-btn
              v-if="perfis.includes('ROLE_ADMIN')"
              id="button-message-to-queue-`${item.details.cnpj}`"
              icon
              color="primary"
              class="mr-2 button-size"
              @click="sendMessageToQueue(item)"
            >
              <v-icon small class="mr-2"> mdi-robot-outline </v-icon>
            </v-btn>
          </AGTooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-main>
</template>
<script>
import AdminServices from '@/services/adminServices.js';

import { mapGetters } from 'vuex';

export default {
  name: 'Estabelecimentos',

  components: {
    AGTooltip: () => import('../commons/AGTooltip.vue'),
  },

  data() {
    return {
      totalEstablishment: 0,
      options: {},
      loading: true,
      headers: [
        { text: 'Razão social matriz', align: 'start', value: 'socialName' },
        { text: 'CNPJ matriz', value: 'details.matriz', sortable: false },
        { text: 'CNPJ', value: 'details.cnpj', sortable: false },
        { text: 'CNAE', value: 'details.cnae', sortable: false },
        { text: 'UF', align: 'start', value: 'details.stateCode', sortable: false },
        { text: 'Cidade', align: 'start', value: 'details.city', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false },
      ],

      estabelecimentos: [],
      isAdmin: false,
    };
  },

  watch: {
    options: {
      handler() {
        this.carregarGrid();
      },
    },
  },

  computed: {
    ...mapGetters({
      perfis: 'user/userAuthorities',
    })
  },

  mounted() {
    this.carregarGrid();
  },

  methods: {
    async carregarGrid() {
      const params = this.pegarParamsParaRequest();
      try {
        const response = await AdminServices.establishment.get(params);
        let EstabelecimentosResp = response.data.content;

        this.totalEstablishment = response.data.totalElements;

        let keys = [];
        let listado = [];
        Object.entries(EstabelecimentosResp).forEach(([key, entries]) => {
          keys.push(key);
          let establishment = {
            socialName: entries.socialName,
            details: entries.details,
          };
          if(!establishment.details.matriz) {
            establishment.details.matriz = establishment.details.cnpj;
          }
          listado.push(establishment);
        });

        this.estabelecimentos = listado;

        this.loading = false;
      } catch (e) {
        localStorage.removeItem('access_Token');
        localStorage.removeItem('refresh_Token');
        this.$router.push('/');
      }
    },

    pegarParamsParaRequest() {
      let params = {};

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      if (page) {
        params['page'] = page - 1;
      }

      if (itemsPerPage) {
        params['size'] = itemsPerPage;
      }
      if (sortBy.length === 1) {
        params['sort'] = sortBy[0];
        params['sort'] += ',' + (sortDesc[0] === true ? 'desc' : 'asc');
      }

      return params;
    },

    sendMessageToQueue(item){
      AdminServices.establishment.sendMessageToQueue(item.details.cnpj);
    }
  },
};
</script>
